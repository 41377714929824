import { FC, useMemo, useState, Dispatch, SetStateAction } from "react";
import { Modal, Button, Typography, Form, Select, message } from "antd";
import { Spacer } from "components";
import { ErrorMessage } from "enums";
import useSWR from "swr";
import { axiosAuth } from "helpers";
import { CheckCircleOutlined } from "@ant-design/icons";

const { Title } = Typography;

type Props = {
  isVisible: boolean;
  onClose: () => void;
  schoolId: string;
  childId?: string | undefined;
  staffId?: string | undefined;
};

const TransferModal: FC<Props> = ({ isVisible, onClose, schoolId, childId, staffId }) => {
  const [transferDone, setTransferDone] = useState<boolean>(false);

  const childTitle = !!childId ? "Transfer this child to new school" : "";
  const staffTitle = !!staffId ? "Transfer this staff to new school" : "";
  const modalTitle = childTitle || staffTitle;

  return (
    <Modal
      title={
        transferDone ? (
          false
        ) : (
          <Title
            level={4}
            style={{
              color: "#E50A5C",
              paddingBottom: "0px",
              marginBottom: "0px",
            }}
          >
            {modalTitle}
          </Title>
        )
      }
      visible={isVisible}
      onCancel={onClose}
      closable={transferDone ? false : true}
      footer={false}
    >
      {transferDone ? (
        <TransferDone onClose={onClose} childId={childId} staffId={staffId} />
      ) : (
        <TransferForm
          schoolId={schoolId}
          childId={childId}
          staffId={staffId}
          setTransferDone={setTransferDone}
          onClose={onClose}
        />
      )}
    </Modal>
  );
};

export default TransferModal;

type TransferDoneProps = {
  onClose: () => void;
  childId?: string | undefined;
  staffId?: string | undefined;
};

const TransferDone: FC<TransferDoneProps> = ({ onClose, childId, staffId }) => {
  const childTitle = !!childId ? "Child Transfer request has been sent to the receiving school" : "";
  const staffTitle = !!staffId ? "Staff Transfer request has been sent to the receiving school" : "";
  const transferDoneTitle = childTitle || staffTitle;

  return (
    <div style={{ padding: "30px", textAlign: "center" }}>
      <CheckCircleOutlined style={{ color: "#20AE5C", fontSize: "50px", marginBottom: "16px" }} />
      <h1 style={{ fontWeight: "600", fontSize: "24px", marginBottom: "16px" }}>Transfer Request Made!</h1>
      <p style={{ fontWeight: "400", marginBottom: "16px" }}>{transferDoneTitle}</p>
      <Button
        type="primary"
        htmlType="button"
        style={{
          backgroundColor: "#20AE5C",
          borderColor: "#20AE5C",
          width: "100%",
        }}
        onClick={onClose}
      >
        Done
      </Button>
    </div>
  );
};

type TransferFormProps = {
  schoolId: string;
  childId?: string | undefined;
  staffId?: string | undefined;
  setTransferDone: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
};

const TransferForm: FC<TransferFormProps> = ({ schoolId, childId, staffId, setTransferDone, onClose }) => {
  const [form] = Form.useForm();
  const [countryId, setCountryId] = useState<string | undefined>(undefined);

  const { data: countries } = useSWR<any>(`V2/country/list?page=1&pageSize=5000`);
  const { data: schools } = useSWR<any>(
    countryId ? `V2/school/list?page=1&pageSize=10000&countryId=${countryId}&exclude=${schoolId}` : null
  );

  const countriesList = useMemo(
    () => countries?.data?.map((item: any) => ({ label: item.title, value: item.id })),
    [countries]
  );

  const schoolsList = useMemo(
    () => schools?.data?.map((item: any) => ({ label: item.name, value: item.id })),
    [schools]
  );

  const handleCountryChange = (value: string) => {
    setCountryId(value);
    form.setFieldsValue({ school: undefined });
  };

  const onFinish = async (values: any) => {
    try {
      const { toSchoolId } = values;

      await axiosAuth.post(`transfer/create`, {
        childId: !!childId ? Number(childId) : null,
        employeeId: !!staffId ? Number(staffId) : null,
        fromSchoolId: Number(schoolId),
        toSchoolId: Number(toSchoolId),
        for: !!childId ? "child" : "staff",
      });
      setTransferDone(true);
      // message.success("Child Transfer request has been sent to the receiving school");
    } catch (error: any) {
      onClose();

      if (typeof error === "object" && error !== null && "response" in error) {
        const errResponse = (error as { response: { data: { message: string } } }).response;
        message.error(`${errResponse.data.message}`);
      } else {
        message.error("An error occurred during transfer");
      }
    } finally {
    }
  };
  
  return (
    <Form name="basic" initialValues={{}} onFinish={onFinish} form={form}>
      <Form.Item
        label="Select Country"
        name="countryId"
        rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
        style={{ flex: 1 }}
      >
        <Select
          showSearch
          allowClear
          options={countriesList?.sort((a: any, b: any) => a.label - b.label)}
          onChange={handleCountryChange}
          filterOption={(input, option) =>
            (typeof option?.label === "string" ? option.label.toLowerCase() : "").indexOf(
              input.toLowerCase()
            ) >= 0
          }
        />
      </Form.Item>

      <Form.Item
        label="Select New School"
        name="toSchoolId"
        rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
        style={{ flex: 1 }}
      >
        <Select
          showSearch
          allowClear
          options={schoolsList?.sort((a: any, b: any) => a.label - b.label)}
          disabled={!countryId}
          filterOption={(input, option) =>
            (typeof option?.label === "string" ? option.label.toLowerCase() : "").indexOf(
              input.toLowerCase()
            ) >= 0
          }
          style={{ paddingLeft: countryId ? "0px" : "11px", margin: "0px" }}
        />
      </Form.Item>

      <Spacer size={10} />
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{
            backgroundColor: "#E50A5C",
            borderColor: "#E50A5C",
            width: "100%",
          }}
        >
          Transfer
        </Button>
      </Form.Item>
    </Form>
  );
};
