export const getReportsListUrl = (kidId: number) => `report/child/${kidId}`;

export const getWebReportsListUrl = (kidId: number) => `report/web/child/${kidId}`;

export const getShareReportUrl = (reportId: number) =>
  `report/send/${reportId}`;

export const getSignedReportUrl = (reportId: number) => `report/${reportId}`;

export const getSendReportUrl = () => "report";

export const getDeleteReportUrl = (reportId: number) => `report/${reportId}`;
