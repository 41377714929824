import { FC } from "react";
import { Modal, Button, message } from "antd";

// HELPERS
import { axiosAuth } from "helpers";

type Props = {
  isVisible: boolean;
  onClose: () => void;
  childId?: string | undefined;
  staffId?: string | undefined;
};

const CacnelTransferModal: FC<Props> = ({ isVisible, onClose, childId, staffId }) => {
  const HandleOnYes = async () => {
    try {
      await axiosAuth.delete(
        `/transfer/cancel-transfer?for=${!!childId ? "child" : "staff"}&id=${childId || staffId}`
      );
      message.success(`${!!childId ? "Child" : "Staff"} Transfer request has been successfully canceled.`);
    } catch (error: any) {
      if (typeof error === "object" && error !== null && "response" in error) {
        const errResponse = (error as { response: { data: { message: string } } }).response;
        message.error(`${errResponse.data.message}`);
      } else {
        message.error("An error occurred during transfer");
      }
    } finally {
      onClose();
    }
  };

  return (
    <Modal title={false} visible={isVisible} onCancel={onClose} closable={false} footer={false}>
      <div style={{ padding: "20px", textAlign: "center" }}>
        <h1 style={{ fontWeight: "600", fontSize: "24px", marginBottom: "16px" }}>Cancel Transfer</h1>
        <p style={{ fontWeight: "400", marginBottom: "30px" }}>
          Are you sure you want to cancel your transfer request?
        </p>

        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "20px" }}>
          <Button
            type="primary"
            htmlType="button"
            style={{
              backgroundColor: "#BFBFBF",
              borderColor: "#BFBFBF",
              width: "120px",
            }}
            onClick={onClose}
          >
            No
          </Button>{" "}
          <Button
            type="primary"
            htmlType="button"
            style={{
              backgroundColor: "#BDD000",
              borderColor: "#BDD000",
              width: "120px",
            }}
            onClick={HandleOnYes}
          >
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CacnelTransferModal;
