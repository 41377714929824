import { FC, useEffect, useMemo, useState } from "react";
import { Modal, Button, Typography, Form, Select, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Spacer } from "components";
import useSWR from "swr";
import { PartnerType } from "screens/Partners/Partners";
import { axiosAuth, normFile } from "helpers";
import { allDocumentTypesList } from "const";
import { ErrorMessage } from "enums";

const { Title, Text } = Typography;

export type InitialData = {
  userType: string;
  uploadId: number;
  type: string | number;
  status: string | number;
  documentId?: number;
};

type Props = {
  isVisible: boolean;
  onClose: () => void;
  initialData: InitialData | null;
  afterSubmit: () => Promise<any>;
  documentTypesList: {
    label: string;
    value: number;
  }[];
};

const DocumentModal: FC<Props> = ({ isVisible, onClose, initialData, afterSubmit, documentTypesList }) => {
  const [isSubmitPending, setIsSubmitPending] = useState(false);
  const [form] = Form.useForm();
  const title = initialData ? "Update Document" : "Create Document";

  const { data: partnersData, error: partnersError } = useSWR<PartnerType[]>("partner");

  const { data: kidsData, error: kidsError } = useSWR<any[]>("child/list");

  const { data: staffData, error: staffError } = useSWR<any[]>("employee/list");

  const { data: schoolsData, error: schoolsError } = useSWR<any[]>("school");

  const partnersList = useMemo(
    () => partnersData?.map(item => ({ label: item.name, value: item.id })),
    [partnersData]
  );
  const kidsList = useMemo(
    () =>
      kidsData?.map(
        ({ firstName, lastName, id }) => ({ label: `${firstName} ${lastName}`, value: id })
      ),
    [kidsData]
  );

  const staffList = useMemo(
    () =>
      staffData?.map(
        ({ firstName, lastName, id }) => ({ label: `${firstName} ${lastName}`, value: id })
      ),
    [staffData]
  );

  const schoolsList = useMemo(
    () => schoolsData?.map((item: any) => ({ label: item.name, value: item.id })),
    [schoolsData]
  );

  useEffect(() => {
    setTimeout(() => {
      form.resetFields();
    }, 0);
  }, [JSON.stringify(initialData)]);

  const onFinish = async (values: any) => {
    try {
      setIsSubmitPending(true);
      let bodyFormData = new FormData();
      if (values.document) {
        bodyFormData.append("document", values.document.originFileObj);
        bodyFormData.append("fileName", values.document.name);
      }
      if (values.type !== undefined) {
        bodyFormData.append("type", `${values.type}`);
      }
      if (values.status !== undefined) {
        bodyFormData.append("status", values.status);
      }
      bodyFormData.append(`${values.userType}Id`, `${values.uploadId}`);
      if (initialData) {
        await axiosAuth.post(`document/update/${initialData?.documentId}`, bodyFormData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      } else {
        await axiosAuth.post(`document/create`, bodyFormData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      }
      await afterSubmit();
      message.success("Document edited successfully");
    } catch (error) {
      message.error("Error when editing document");
    } finally {
      setIsSubmitPending(false);
      onClose();
    }
  };

  const dataToShow: Record<string, any> = {
    child: kidsList,
    partner: partnersList,
    employee: staffList,
    school: schoolsList,
  };

  return (
    <Modal
      title={
        <Title
          level={4}
          style={{
            color: "#E50A5C",
            paddingBottom: "0px",
            marginBottom: "0px",
          }}
        >
          {title}
        </Title>
      }
      visible={isVisible}
      onCancel={onClose}
      footer={false}
    >
      <Form name="basic" initialValues={initialData ?? {}} onFinish={onFinish} form={form}>
        <Form.Item
          label="User Type"
          name="userType"
          rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
          style={{ flex: 1 }}
        >
          <Select
            onChange={() => {
              form.resetFields(["user"]);
            }}
          >
            <Select.Option value="child">Child</Select.Option>
            <Select.Option value="employee">Staff</Select.Option>
            <Select.Option value="partner">Partner</Select.Option>
            <Select.Option value="school">School</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prev, cur) => prev.userType !== cur.userType}>
          {({ getFieldValue }) => {
            const aa: string = getFieldValue("userType");
            const data = dataToShow[aa] ?? [];
            return aa ? (
              <Form.Item
                label="User"
                name="uploadId"
                rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
                style={{ flex: 1 }}
              >
                <Select>
                  {data.map(({ label, value }: any) => (
                    <Select.Option value={value}>{label}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : null;
          }}
        </Form.Item>
        <Spacer size={10} />
        <Form.Item
          label="Type"
          name="type"
          rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
          style={{ flex: 1 }}
          initialValue={
            initialData?.type
              ? {
                  label: documentTypesList.find(doc => doc.value === initialData.type)?.label,
                  value: initialData.type,
                }
              : undefined
          }
        >
          <Select
            showSearch
            allowClear
            options={documentTypesList}
            filterOption={(input, option) =>
              (option?.label?.toLocaleString() ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          />
        </Form.Item>

        <Form.Item
          label="Status"
          name="status"
          rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
          style={{ flex: 1 }}
        >
          <Select>
            <Select.Option value={10}>Active</Select.Option>
            <Select.Option value={0}>Inactive</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item noStyle shouldUpdate={true}>
          {({ getFieldValue, setFieldsValue }) => {
            const document = getFieldValue("document");
            return (
              <Form.Item
                label="Document"
                name="document"
                valuePropName="fileList"
                style={{
                  marginBottom: "0px",
                  marginRight: "20px",
                  position: "relative",
                }}
                getValueFromEvent={normFile}
                rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
              >
                <Upload
                  showUploadList={false}
                  beforeUpload={() => false}
                  onChange={info => {
                    setFieldsValue({
                      document: info.fileList[0],
                    });
                  }}
                >
                  <Button icon={<UploadOutlined />}>Select document</Button>
                </Upload>
                <Text
                  ellipsis
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: "0%",
                    width: "150px",
                  }}
                >
                  {document?.name}
                </Text>
              </Form.Item>
            );
          }}
        </Form.Item>
        <Spacer size={10} />
        <Form.Item wrapperCol={{ offset: 20 }}>
          <Button
            loading={isSubmitPending}
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: "#BDD000",
              borderColor: "#BDD000",
            }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DocumentModal;
