import {
  Button,
  Form,
  Upload,
  Typography,
  Row,
  Col,
  Input,
  Select,
  DatePicker,
  Divider,
  Switch,
  Checkbox,
  Spin,
  Timeline,
  message,
} from "antd";
import { EditFilled } from "@ant-design/icons";
import {
  Card,
  Container,
  CustomBgImage,
  CustomLabel,
  CustomPhoneInput,
  DeleteButton,
  Spacer,
} from "components";
import { FC, useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import dayjs from "dayjs";
import { axiosAuth, normFile } from "helpers";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import { StaffDetailsNavigationParams, StaffType } from "types";
import {
  employeeRoles,
  staffDocumentTypesList,
  staffQualificationsList,
  staffRequiredDocumentTypesList,
} from "const";
import Documents from "components/Documents";
import { EmployeeRole, ErrorMessage, ViewType } from "enums";
import { roles } from "const";
import getImageBase64 from "helpers/getImageBase64";
import Attendance from "screens/Kid/Attendance";
import ResetPassword from "components/ResetPassword";
import SchoolShiftInput from "components/SchoolShiftInput";
import { useAppSelector } from "store";
import { Evaluation, SmartGoals } from "./components";
import TransferModal from "screens/Transfers/TransferModal";
import CancelTransferModal from "screens/Transfers/CancelTransferModal";

const webProfileRoles = [30, 110, 120, 130, 140];

const { Title, Text } = Typography;

type Props = {
  viewType: ViewType;
} & RouteComponentProps<StaffDetailsNavigationParams>;

type PickedTab = "main" | "goals" | "attendance" | "evaluation";

const AddStaff: FC<Props> = ({ match, viewType }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isPreviewMode = searchParams.get("isPreviewMode") === "true";

  const role = useAppSelector(store => store.user.role);
  const isSchoolManagerOrAdministrator =
    !!role && [EmployeeRole.SCHOOL_MANAGER, EmployeeRole.SCHOOL_ADMINISTRATOR].includes(role);

  const schoolId = match.params.id;
  const staffId = match.params.staffId;

  const { deleteSchoolUsers } = useAppSelector(store => store.user.access);

  const [isTransferModalVisible, setIsTransferModalVisible] = useState(false);
  const [isCancelTransferModalVisible, setIsCancelTransferModalVisible] = useState(false);

  const [isStaffUpdatePending, setIsStaffUpdatePending] = useState(false);
  const [isQualificationPending, setIsQualificationPending] = useState(false);
  const [isEmergencyContactPending, setIsEmergencyContactPending] = useState(false);
  const history = useHistory();

  const [pickedTab, setPickedTab] = useState<PickedTab>("main");
  const [mainForm] = Form.useForm();
  const [emergencyForm] = Form.useForm();
  const [notesForm] = Form.useForm();
  const [qualificationForm] = Form.useForm();

  const [isEditMode, setIsEditMode] = useState(!staffId);
  const [isEmergencyEditMode, setIsEmergencyEditMode] = useState(!staffId);
  const {
    data: staffData,
    error: staffError,
    revalidate,
  } = useSWR<StaffType>(staffId ? `employee/config/${staffId}` : null);
  const isDataPending = !!staffId && !staffData && !staffError;
  const activeSchoolId = staffData?.schoolId ?? match.params.id;

  const { data: canTransfer, revalidate: canTransferRevalidate } = useSWR<{ canTransfer: boolean }>(
    staffId ? `transfer/can-transfer?id=${staffId}&for=staff` : null
  );
  const canTransferStaff = canTransfer?.canTransfer ?? false;

  const initialValues = {
    isDeleted: false,
    status: staffData?.status ?? 10,
    firstName: staffData?.firstName,
    lastName: staffData?.lastName,
    countryId: staffData?.countryId,
    dateOfBirth: staffData?.dateOfBirth ? dayjs(staffData?.dateOfBirth) : null,
    languageId: staffData?.languageId,
    visaExpiry: staffData?.visaExpiry ? dayjs(staffData?.visaExpiry) : null,
    address: staffData?.address,
    email: staffData?.email,
    role: staffData?.role,
    startDate: staffData?.startDate ? dayjs(staffData?.startDate) : null,
    shiftId: staffData?.shiftId,
    schoolId: staffData?.schoolId,
    highestQualification: staffData?.highestQualification,
    emergencyAddress: staffData?.highestQualification,
    emergencyKnownMedicalProblems: staffData?.emergencyKnownMedicalProblems,
    emergencyName: staffData?.emergencyName,
    emergencyPhoneNumber: staffData?.emergencyPhoneNumber,
    emergencyRelationship: staffData?.emergencyRelationship,
  };

  const { data: countries, error: countriesError } = useSWR<any[]>(`country`);
  const isCountriesPending = !countries && !countriesError;

  const { data: languages, error: laguagesError } = useSWR<any[]>(`language`);
  const isLanguagesPending = !languages && !laguagesError;

  const { data: schoolsData, error: schoolsError } = useSWR<any[]>(`school`);

  const isSchoolPending = !schoolsData && !schoolsError;

  const all = useSWR(`attendance/config/employee/${staffId}`);

  const countriesList = useMemo(
    () => countries?.map(item => ({ label: item.title, value: item.id })),
    [countries]
  );

  const languagesList = useMemo(
    () => languages?.map(item => ({ label: item.title, value: item.id })),
    [languages]
  );

  const schoolsList = useMemo(
    () => schoolsData?.map((item: any) => ({ label: item.name, value: item.id })),
    [schoolsData]
  );

  useEffect(() => {
    mainForm.resetFields();
    emergencyForm.resetFields();
    notesForm.resetFields();
    qualificationForm.resetFields();
  }, [JSON.stringify(initialValues)]);

  const evaluationEnabled =
    staffData?.role === EmployeeRole.TEACHER ||
    staffData?.role === EmployeeRole.ASSISTANT_TEACHER ||
    staffData?.role === EmployeeRole.HEAD_TEACHER;

  const canSetSmartGoals =
    staffData?.role === EmployeeRole.TEACHER ||
    staffData?.role === EmployeeRole.ASSISTANT_TEACHER ||
    staffData?.role === EmployeeRole.HEAD_TEACHER ||
    staffData?.role === EmployeeRole.SCHOOL_ADMINISTRATOR ||
    staffData?.role === EmployeeRole.SCHOOL_MANAGER;

  const shouldShowEvaluation =
    evaluationEnabled &&
    (role === EmployeeRole.ADMINISTRATOR ||
      role === EmployeeRole.SCHOOL_ADMINISTRATOR ||
      role === EmployeeRole.SCHOOL_MANAGER ||
      role === EmployeeRole.SUPER_ADMIN ||
      role === EmployeeRole.TEACHER ||
      role === EmployeeRole.ASSISTANT_TEACHER);

  const title = staffData ? `Staff - ${staffData.firstName} ${staffData.lastName}` : "Add new staff";

  const selectEditModeProps = {
    showArrow: isEditMode,
    bordered: isEditMode,
    disabled: !isEditMode,
  };

  const activeStyle = {
    backgroundColor: "#BDD000",
    border: "1px solid #BDD000",
    outline: "none",
  };

  const selectedRole = role
    ? Object.entries(roles[role] ?? employeeRoles).map(([key, val]) => ({
        label: val as string,
        value: parseInt(key) as number,
      }))
    : [];

  if (isDataPending) {
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spacer size={40} />
        <Spin spinning />
      </div>
    );
  }

  const pickedMainTab = pickedTab === "main";
  const pickedAttendanceTab = pickedTab === "attendance";
  const pickedEvaluationTab = pickedTab === "evaluation";
  const pickedGoalsTab = pickedTab === "goals";

  const staffDocumentTypes = staffData?.document?.map(doc => doc.type);
  const requiredDocuments =
    staffData?.role === EmployeeRole.TEACHER || staffData?.role === EmployeeRole.ASSISTANT_TEACHER
      ? staffRequiredDocumentTypesList
      : [];

  const renderContent = () => {
    if (pickedAttendanceTab) {
      return <Attendance attendanceUrl={`attendance/config/employee/${staffId}`} role="teacher" />;
    }
    if (pickedEvaluationTab && shouldShowEvaluation) {
      return <Evaluation employeeId={staffData?.id} role={staffData?.role} />;
    }
    if (pickedGoalsTab && canSetSmartGoals) {
      return <SmartGoals employeeId={staffData?.id} />;
    }
    if (pickedEvaluationTab && shouldShowEvaluation) {
      return <Evaluation employeeId={staffData?.id} role={staffData?.role} />;
    }
    if (pickedGoalsTab && canSetSmartGoals) {
      return <SmartGoals employeeId={staffData?.id} />;
    }
    return (
      <>
        <Card>
          <Form
            form={mainForm}
            autoComplete="off"
            initialValues={{ ...initialValues }}
            onFinish={async values => {
              try {
                setIsStaffUpdatePending(true);
                let image = undefined;
                if (values.newImage) {
                  image = await getImageBase64(values.newImage);
                }
                const schoolId = values.schoolId ?? activeSchoolId;
                const isWebAppUser = webProfileRoles.includes(values.role);
                const additionalUpdate = isWebAppUser
                  ? {
                      role: values.role,
                      mainRole: values.role,
                      school: Array.isArray(schoolId) ? schoolId : [schoolId],
                    }
                  : {
                      role: values.role,
                      mainRole: values.role === 30 ? 30 : 0,
                    };

                const additionalCreate = isWebAppUser
                  ? {
                      role: values.role,
                      mainRole: values.role,
                      school: Array.isArray(schoolId) ? schoolId : [schoolId],
                    }
                  : {
                      role: values.role,
                      mainRole: values.role === 30 ? 30 : 0,
                    };

                const schoolIdInUrl = isWebAppUser ? "null" : schoolId;

                if (isWebAppUser) {
                  delete values.schoolId;
                }
                const { newImage, ...rest } = values;
                if (staffId) {
                  await axiosAuth.post(`employee/update/${staffId}`, {
                    ...rest,
                    userId: staffData?.userId,
                    ...additionalUpdate,
                    image,
                  });
                  message.success("Employee was updated succesfully");
                } else {
                  await axiosAuth.post(`employee/create/${schoolIdInUrl}`, {
                    ...rest,
                    ...additionalCreate,
                    image,
                  });
                  message.success("Employee was created succesfully");
                  history.goBack();
                }
                await revalidate();
                setIsEditMode(false);
                setIsStaffUpdatePending(false);
              } catch (e: any) {
                setIsStaffUpdatePending(false);
                if (e.message.includes("302")) {
                  mainForm.setFields([
                    {
                      name: "email",
                      touched: true,
                      errors: ["This email is already used"],
                    },
                  ]);
                }
              }
            }}
          >
            <Row>
              <Col flex={1} />
              <Col flex={1}>
                <Title
                  level={4}
                  style={{
                    color: "#E50A5C",
                    margin: "0px",
                    textAlign: "center",
                  }}
                >
                  {title}
                </Title>
              </Col>
              <Col flex={1}>
                {!isPreviewMode && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      flexDirection: "row",
                    }}
                  >
                    {!!staffId && (
                      <>
                        <Switch
                          checkedChildren="Edit mode"
                          unCheckedChildren="Edit mode"
                          defaultChecked
                          onChange={val => setIsEditMode(val)}
                          checked={isEditMode}
                        />
                      </>
                    )}

                    <Form.Item noStyle shouldUpdate={(prev, next) => prev.status !== next.status}>
                      {({ getFieldValue, setFieldsValue }) => {
                        return (
                          <Form.Item name="status" style={{ marginBottom: 0, marginLeft: "10px" }}>
                            <Switch
                              checkedChildren="Active"
                              unCheckedChildren="Active"
                              onChange={val => setFieldsValue({ status: val ? 10 : 0 })}
                              checked={getFieldValue("status") === 10}
                              disabled={!isEditMode}
                            />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </div>
                )}
              </Col>
            </Row>

            {/* {!!staffId && (
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  flexDirection: "column",
                }}
              >
                <Switch
                  checkedChildren="Edit mode"
                  unCheckedChildren="Edit mode"
                  defaultChecked
                  onChange={val => setIsEditMode(val)}
                  checked={isEditMode}
                />
                <Spacer />
              </div>
            )} */}
            <Spacer />

            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                flexDirection: "column",
              }}
            >
              {!isPreviewMode && !!isSchoolManagerOrAdministrator && !!canTransferStaff && (
                <Button
                  type="primary"
                  htmlType="button"
                  style={{
                    backgroundColor: "#BDD000",
                    borderColor: "#BDD000",
                  }}
                  onClick={() => setIsTransferModalVisible(true)}
                >
                  Transfer
                </Button>
              )}

              {!isPreviewMode && !!isSchoolManagerOrAdministrator && !canTransferStaff && (
                <Button
                  type="primary"
                  htmlType="button"
                  style={{
                    backgroundColor: "#BFBFBF",
                    borderColor: "#BFBFBF",
                  }}
                  onClick={() => setIsCancelTransferModalVisible(true)}
                >
                  Cancel Transfer
                </Button>
              )}
            </div>
            <Spacer />

            <Row gutter={[48, 0]}>
              <Col span={4}>
                <Row>
                  <Col span={24}>
                    <Form.Item noStyle shouldUpdate>
                      {({ getFieldValue }) => {
                        return (
                          <>
                            <CustomBgImage src={staffData?.image ?? ""} type="carer" />
                            {isEditMode && (
                              <>
                                {!!staffId && (
                                  <Col span={24}>
                                    <Form.Item name="isDeleted" shouldUpdate valuePropName="checked">
                                      <Checkbox>Delete current photo</Checkbox>
                                    </Form.Item>
                                  </Col>
                                )}
                                <Spacer size={10} />
                                <Col span={24}>
                                  <Form.Item
                                    name="newImage"
                                    valuePropName="fileList"
                                    getValueFromEvent={normFile}
                                    labelCol={{ span: 24 }}
                                  >
                                    <Upload name="avatar" listType="picture" beforeUpload={() => false}>
                                      <Button icon={<EditFilled />} type="dashed" style={{ width: "100%" }}>
                                        New photo
                                      </Button>
                                    </Upload>
                                  </Form.Item>
                                </Col>
                              </>
                            )}
                          </>
                        );
                      }}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={20}>
                <Row gutter={[24, 0]}>
                  <Col span={6}>
                    <Form.Item
                      name="firstName"
                      labelCol={{ span: 24 }}
                      label={<CustomLabel>First name</CustomLabel>}
                      rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
                    >
                      <Input
                        disabled={!isEditMode}
                        style={
                          isEditMode
                            ? {}
                            : {
                                border: "none",
                                backgroundColor: "white",
                                padding: "4px 11px 4px 0px",
                              }
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="lastName"
                      labelCol={{ span: 24 }}
                      label={<CustomLabel>Last name</CustomLabel>}
                      rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
                    >
                      <Input
                        disabled={!isEditMode}
                        style={
                          isEditMode
                            ? {}
                            : {
                                border: "none",
                                backgroundColor: "white",
                                padding: "4px 11px 4px 0px",
                              }
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="countryId"
                      labelCol={{ span: 24 }}
                      label={<CustomLabel>Country</CustomLabel>}
                      rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
                    >
                      <Select
                        showSearch
                        allowClear
                        options={countriesList}
                        filterOption={(input, option) =>
                          (option?.label?.toLocaleString() ?? "")
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        {...selectEditModeProps}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="dateOfBirth"
                      labelCol={{ span: 24 }}
                      label={<CustomLabel>Date of birth</CustomLabel>}
                    >
                      <DatePicker style={{ width: "100%" }} {...selectEditModeProps} />
                    </Form.Item>
                  </Col>
                  <Col span={6} offset={6}>
                    <Form.Item
                      name="languageId"
                      labelCol={{ span: 24 }}
                      label={<CustomLabel>Language</CustomLabel>}
                      rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
                    >
                      <Select
                        showSearch
                        allowClear
                        options={languagesList}
                        filterOption={(input, option) =>
                          (option?.label?.toLocaleString() ?? "")
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        {...selectEditModeProps}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="visaExpiry"
                      labelCol={{ span: 24 }}
                      label={<CustomLabel>Visa expiry</CustomLabel>}
                    >
                      <DatePicker style={{ width: "100%" }} {...selectEditModeProps} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="address"
                      labelCol={{ span: 24 }}
                      label={<CustomLabel>Address</CustomLabel>}
                    >
                      <Input
                        disabled={!isEditMode}
                        style={
                          isEditMode
                            ? {}
                            : {
                                border: "none",
                                backgroundColor: "white",
                                padding: "4px 11px 4px 0px",
                              }
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6} offset={12}>
                    <Form.Item
                      name="startDate"
                      labelCol={{ span: 24 }}
                      label={<CustomLabel>Start date</CustomLabel>}
                    >
                      <DatePicker style={{ width: "100%" }} {...selectEditModeProps} />
                    </Form.Item>
                  </Col>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prev, next) => prev.role !== next.role || prev.schoolId !== next.schoolId}
                  >
                    {({ getFieldValue }) => {
                      const schoolId = getFieldValue("schoolId") ?? activeSchoolId;

                      const validSchoolId = schoolId
                        ? Array.isArray(schoolId)
                          ? schoolId[0]
                          : schoolId
                        : null;
                      if (!webProfileRoles.includes(parseInt(getFieldValue("role")))) {
                        return (
                          <Col span={6}>
                            <Form.Item
                              name="shiftId"
                              labelCol={{ span: 24 }}
                              label={<CustomLabel>Shift</CustomLabel>}
                            >
                              <SchoolShiftInput schoolId={validSchoolId} {...selectEditModeProps} />
                            </Form.Item>
                          </Col>
                        );
                      }
                      return null;
                    }}
                  </Form.Item>
                  {viewType === ViewType.MAIN_PANEL && (
                    <Col span={6} offset={18}>
                      <Form.Item noStyle shouldUpdate={(prev, next) => prev.role !== next.role}>
                        {({ getFieldValue, setFieldsValue }) => {
                          const role = getFieldValue("role");
                          return (
                            <Form.Item
                              name="schoolId"
                              labelCol={{ span: 24 }}
                              label={<CustomLabel>School</CustomLabel>}
                              rules={[
                                {
                                  required: true,
                                  message: ErrorMessage.REQUIRED,
                                },
                              ]}
                            >
                              <Select
                                {...(role === EmployeeRole.TERRITORY_MANAGER ||
                                role === EmployeeRole.TERRITORY_OWNER
                                  ? { mode: "multiple" }
                                  : {})}
                                showSearch
                                allowClear
                                options={schoolsList}
                                onChange={() => {
                                  setFieldsValue({ shiftId: null });
                                }}
                                filterOption={(input, option) =>
                                  (option?.label?.toLocaleString() ?? "")
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                {...selectEditModeProps}
                              />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
            <Col span={24}>
              <Text style={{ fontSize: "1.4rem" }}>Account details</Text>
              <Divider style={{ margin: "10px 0px" }} />
            </Col>
            <Row gutter={[48, 0]}>
              <Col span={6}>
                <Form.Item
                  name="email"
                  labelCol={{ span: 24 }}
                  label={<CustomLabel>Email</CustomLabel>}
                  rules={[
                    { required: true, message: ErrorMessage.REQUIRED },
                    { type: "email", message: ErrorMessage.EMAILA },
                  ]}
                >
                  <Input
                    disabled={!isEditMode}
                    style={
                      isEditMode
                        ? {}
                        : {
                            border: "none",
                            backgroundColor: "white",
                            padding: "4px 11px 4px 0px",
                          }
                    }
                  />
                </Form.Item>
              </Col>
              {!staffId && (
                <Col span={6}>
                  <Form.Item
                    help="If no password provided, it will be generated automatically"
                    name="password"
                    labelCol={{ span: 24 }}
                    label={<CustomLabel>Password</CustomLabel>}
                  >
                    <Input.Password />
                  </Form.Item>
                </Col>
              )}
              <Col span={6}>
                <Form.Item
                  name="role"
                  labelCol={{ span: 24 }}
                  label={<CustomLabel>Role</CustomLabel>}
                  rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
                >
                  <Select options={selectedRole} {...selectEditModeProps} />
                </Form.Item>
              </Col>
            </Row>
            {!isPreviewMode && (
              <>
                <Divider style={{ margin: "20px 0px", marginBottom: "20px" }} />
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  {!!(staffId && staffData) ? <ResetPassword email={staffData.email ?? ""} /> : <div />}
                  {isEditMode && (
                    <div>
                      <Form.Item>
                        <Button
                          loading={isStaffUpdatePending}
                          type="primary"
                          htmlType="submit"
                          style={{
                            backgroundColor: "#BDD000",
                            borderColor: "#BDD000",
                          }}
                        >
                          Submit
                        </Button>
                      </Form.Item>
                      {staffId && deleteSchoolUsers && (
                        <DeleteButton
                          onSubmit={async () => {
                            await axiosAuth.get(`employee/delete/${staffId}`);
                            history.goBack();
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
          </Form>
        </Card>
        <Spacer size={40} />
        {!isPreviewMode && !!staffId && (
          <>
            <Card>
              <Form
                form={qualificationForm}
                initialValues={{ ...initialValues }}
                onFinish={async values => {
                  setIsQualificationPending(true);
                  if (staffData) {
                    const schoolId = staffData.school ?? staffData.schoolId ?? activeSchoolId;

                    const isWebAppUser = webProfileRoles.includes(staffData.role);

                    const additionalUpdate = isWebAppUser
                      ? {
                          role: values.role,
                          mainRole: values.role,
                          school: Array.isArray(schoolId) ? schoolId : [schoolId],
                        }
                      : {
                          role: values.role,
                          mainRole: values.role === 30 ? 30 : 0,
                        };

                    await axiosAuth.post(`employee/update/${staffId}`, {
                      ...values,
                      userId: staffData?.userId,
                      ...additionalUpdate,
                    });
                    await revalidate();
                  }
                  setIsQualificationPending(false);
                }}
              >
                <Title
                  level={4}
                  style={{
                    color: "#E50A5C",
                    margin: "0px",
                    textAlign: "center",
                  }}
                >
                  Qualifications
                </Title>
                <Spacer />
                <Form.Item
                  name="highestQualification"
                  labelCol={{ span: 24 }}
                  label={<CustomLabel>Highest qualification</CustomLabel>}
                >
                  <Select
                    showSearch
                    allowClear
                    options={staffQualificationsList}
                    filterOption={(input, option) =>
                      (option?.label?.toLocaleString() ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  />
                </Form.Item>
                <Divider style={{ margin: "10px 0px", marginBottom: "20px" }} />
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Form.Item>
                    <Button
                      loading={isQualificationPending}
                      type="primary"
                      htmlType="submit"
                      style={{
                        backgroundColor: "#BDD000",
                        borderColor: "#BDD000",
                      }}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </Card>
            <Spacer size={40} />
          </>
        )}
        {!isPreviewMode && !!staffId && (
          <>
            <Card>
              <Form
                form={emergencyForm}
                initialValues={{ ...initialValues }}
                onFinish={async values => {
                  setIsEmergencyContactPending(true);
                  if (staffData) {
                    const schoolId = staffData.school ?? staffData.schoolId ?? activeSchoolId;
                    const isWebAppUser = webProfileRoles.includes(staffData.role);

                    const additionalUpdate = isWebAppUser
                      ? {
                          role: values.role,
                          mainRole: values.role,
                          school: Array.isArray(schoolId)
                            ? typeof schoolId[0] === "object"
                              ? schoolId[0].schoolId
                              : schoolId
                            : [schoolId],
                        }
                      : {
                          role: values.role,
                          mainRole: values.role === 30 ? 30 : 0,
                        };
                    await axiosAuth.post(`employee/update/${staffId}`, {
                      ...values,
                      userId: staffData?.userId,
                      ...additionalUpdate,
                    });
                    await revalidate();
                    setIsEmergencyContactPending(false);
                    setIsEmergencyEditMode(false);
                  }
                }}
              >
                <Row>
                  <Col flex={1}>
                    <Title
                      level={4}
                      style={{
                        color: "#E50A5C",
                        margin: "0px",
                        textAlign: "center",
                      }}
                    >
                      Emergency contact
                    </Title>
                  </Col>
                  <Col>
                    <Switch
                      checkedChildren="Edit mode"
                      unCheckedChildren="Edit mode"
                      defaultChecked
                      onChange={val => setIsEmergencyEditMode(val)}
                      checked={isEmergencyEditMode}
                    />
                  </Col>
                </Row>
                <Spacer size={40} />
                <Row gutter={[48, 0]}>
                  <Col span={12}>
                    <Form.Item
                      name="emergencyName"
                      labelCol={{ span: 24 }}
                      label={<CustomLabel>Emergency name</CustomLabel>}
                    >
                      <Input
                        disabled={!isEmergencyEditMode}
                        style={
                          isEmergencyEditMode
                            ? {}
                            : {
                                border: "none",
                                backgroundColor: "white",
                                padding: "4px 11px 4px 0px",
                              }
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="emergencyRelationship"
                      labelCol={{ span: 24 }}
                      label={<CustomLabel>Emergency Relationship</CustomLabel>}
                    >
                      <Input
                        disabled={!isEmergencyEditMode}
                        style={
                          isEmergencyEditMode
                            ? {}
                            : {
                                border: "none",
                                backgroundColor: "white",
                                padding: "4px 11px 4px 0px",
                              }
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="emergencyPhoneNumber"
                      labelCol={{ span: 24 }}
                      label={<CustomLabel>Emergency phone number</CustomLabel>}
                    >
                      <CustomPhoneInput
                        international
                        value=""
                        onChange={() => {}}
                        disabled={!isEmergencyEditMode}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="emergencyAddress"
                      labelCol={{ span: 24 }}
                      label={<CustomLabel>Emergency address</CustomLabel>}
                    >
                      <Input
                        disabled={!isEmergencyEditMode}
                        style={
                          isEmergencyEditMode
                            ? {}
                            : {
                                border: "none",
                                backgroundColor: "white",
                                padding: "4px 11px 4px 0px",
                              }
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="emergencyKnownMedicalProblems"
                      labelCol={{ span: 24 }}
                      label={<CustomLabel>Emergency known medical problems</CustomLabel>}
                    >
                      <Input.TextArea
                        placeholder="-"
                        disabled={!isEmergencyEditMode}
                        style={
                          isEmergencyEditMode
                            ? {}
                            : {
                                border: "none",
                                backgroundColor: "white",
                                padding: "4px 11px 4px 0px",
                              }
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {isEmergencyEditMode && (
                  <>
                    <Divider style={{ margin: "10px 0px", marginBottom: "20px" }} />
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Form.Item>
                        <Button
                          loading={isEmergencyContactPending}
                          type="primary"
                          htmlType="submit"
                          style={{
                            backgroundColor: "#BDD000",
                            borderColor: "#BDD000",
                          }}
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </div>
                  </>
                )}
              </Form>
            </Card>
            <Spacer size={40} />
          </>
        )}

        {!isPreviewMode && !!staffId && (
          <Documents
            mandatoryDocuments={requiredDocuments.filter(doc => {
              if (staffDocumentTypes?.includes(doc.value)) {
                return false;
              }
              return true;
            })}
            documentsList={staffData?.document ?? []}
            documentsTypes={staffDocumentTypesList}
            uploadId={parseInt(staffId)}
            userType="employee"
            afterSubmit={revalidate}
          />
        )}
        {!isPreviewMode && !!staffId && staffData && (
          <>
            <Spacer size={40} />
            <Card>
              <Title
                level={4}
                style={{
                  color: "#E50A5C",
                  margin: "0px",
                  textAlign: "center",
                }}
              >
                Notes
              </Title>
              <Spacer size={40} />
              <Timeline mode="alternate">
                {staffData.note?.map(({ createdAt, message }) => (
                  <Timeline.Item label={dayjs(new Date(createdAt * 1000)).format("DD-MM-YYYY")}>
                    {message}
                  </Timeline.Item>
                ))}
              </Timeline>
              <Form
                form={notesForm}
                onFinish={async values => {
                  if (staffData) {
                    const schoolId = staffData.school ?? staffData.schoolId ?? activeSchoolId;

                    const isWebAppUser = webProfileRoles.includes(staffData.role);

                    const additionalUpdate = isWebAppUser
                      ? {
                          role: values.role,
                          mainRole: values.role,
                          school: Array.isArray(schoolId) ? schoolId : [schoolId],
                        }
                      : {
                          role: values.role,
                          mainRole: values.role === 30 ? 30 : 0,
                        };

                    await axiosAuth.post(`employee/update/${staffId}`, {
                      ...values,
                      userId: staffData?.userId,
                      ...additionalUpdate,
                    });
                    await revalidate();
                  }
                }}
                initialValues={{
                  ...initialValues,
                }}
              >
                <Row>
                  <Col flex={1}>
                    <Form.Item name="note" rules={[{ required: true, message: ErrorMessage.REQUIRED }]}>
                      <Input placeholder="create new note" />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                          backgroundColor: "#BDD000",
                          borderColor: "#BDD000",
                        }}
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
            <Spacer size={40} />
          </>
        )}

        {!!isTransferModalVisible && schoolId && staffId && (
          <TransferModal
            isVisible={isTransferModalVisible}
            onClose={() => {
              canTransferRevalidate();
              setIsTransferModalVisible(false);
            }}
            schoolId={schoolId}
            staffId={staffId}
          />
        )}

        {!!isCancelTransferModalVisible && schoolId && staffId && (
          <CancelTransferModal
            isVisible={isCancelTransferModalVisible}
            onClose={() => {
              canTransferRevalidate();
              setIsCancelTransferModalVisible(false);
            }}
            staffId={staffId}
          />
        )}
      </>
    );
  };

  return (
    <>
      <Spin spinning={isDataPending}>
        <Container>
          {!isPreviewMode && !!staffId && (
            <>
              <Row justify="end">
                <Button
                  type={pickedMainTab ? "primary" : "default"}
                  style={{
                    marginRight: "20px",
                    ...(pickedMainTab ? activeStyle : {}),
                  }}
                  onClick={() => setPickedTab("main")}
                >
                  Main
                </Button>
                <Button
                  type={pickedAttendanceTab ? "primary" : "default"}
                  style={pickedAttendanceTab ? activeStyle : {}}
                  onClick={() => setPickedTab("attendance")}
                >
                  Attendance
                </Button>
                {shouldShowEvaluation && (
                  <Button
                    type={pickedEvaluationTab ? "primary" : "default"}
                    style={pickedEvaluationTab ? activeStyle : {}}
                    onClick={() => setPickedTab("evaluation")}
                  >
                    Evaluation
                  </Button>
                )}
                {canSetSmartGoals && (
                  <Button
                    type={pickedGoalsTab ? "primary" : "default"}
                    style={pickedGoalsTab ? activeStyle : {}}
                    onClick={() => setPickedTab("goals")}
                  >
                    Smart Goals
                  </Button>
                )}
              </Row>
              <Spacer />
            </>
          )}
          {renderContent()}
          <Spacer size={80} />
        </Container>
      </Spin>
    </>
  );
};

export default AddStaff;
