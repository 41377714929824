import { EmployeeRole } from '../enums';

export const employeeRoles: Record<number, string> = {
  [EmployeeRole.SECURITY]: 'Security',
  [EmployeeRole.CLEANER]: 'Cleaner',
  [EmployeeRole.NURSE]: 'Nurse',
  [EmployeeRole.NANNY]: 'Nanny',
  [EmployeeRole.ASSISTANT_TEACHER]: 'Assistant teacher',
  [EmployeeRole.TEACHER]: 'Teacher',
  [EmployeeRole.HEAD_TEACHER]: 'Head teacher',
  [EmployeeRole.SUPER_ADMIN]: 'Super admin',
  [EmployeeRole.TERRITORY_OWNER]: 'Territory owner',
  [EmployeeRole.TERRITORY_MANAGER]: 'Territory manager',
  [EmployeeRole.SCHOOL_MANAGER]: 'School manager',
  [EmployeeRole.SCHOOL_ADMINISTRATOR]: 'School administrator',
};

const basisEmployeeRoleList = {
  [EmployeeRole.SECURITY]: 'Security',
  [EmployeeRole.CLEANER]: 'Cleaner',
  [EmployeeRole.NURSE]: 'Nurse',
  [EmployeeRole.NANNY]: 'Nanny',
  [EmployeeRole.ASSISTANT_TEACHER]: 'Assistant teacher',
  [EmployeeRole.TEACHER]: 'Teacher',
  [EmployeeRole.HEAD_TEACHER]: 'Head teacher',
};

const superAdminEmployeeRoleList = {
  ...basisEmployeeRoleList,
  [EmployeeRole.SUPER_ADMIN]: 'Super admin',
  [EmployeeRole.TERRITORY_OWNER]: 'Territory owner',
  [EmployeeRole.TERRITORY_MANAGER]: 'Territory manager',
  [EmployeeRole.SCHOOL_MANAGER]: 'School manager',
  [EmployeeRole.SCHOOL_ADMINISTRATOR]: 'School administrator',
};

const territoryOwnerEmployeeRoleList = {
  ...basisEmployeeRoleList,
  [EmployeeRole.TERRITORY_OWNER]: 'Territory owner',
  [EmployeeRole.TERRITORY_MANAGER]: 'Territory manager',
  [EmployeeRole.SCHOOL_MANAGER]: 'School manager',
  [EmployeeRole.SCHOOL_ADMINISTRATOR]: 'School administrator',
};

const territoryManagerEmployeeRoleList = {
  ...basisEmployeeRoleList,
  [EmployeeRole.TERRITORY_MANAGER]: 'Territory manager',
  [EmployeeRole.SCHOOL_MANAGER]: 'School manager',
  [EmployeeRole.SCHOOL_ADMINISTRATOR]: 'School administrator',
};

const schoolManagerEmployeeRoleList = {
  ...basisEmployeeRoleList,
  [EmployeeRole.SCHOOL_MANAGER]: 'School manager',
  [EmployeeRole.SCHOOL_ADMINISTRATOR]: 'School administrator',
};

export const roles: Record<number, any> = {
  [EmployeeRole.SUPER_ADMIN]: superAdminEmployeeRoleList,
  [EmployeeRole.TERRITORY_OWNER]: territoryOwnerEmployeeRoleList,
  [EmployeeRole.TERRITORY_MANAGER]: territoryManagerEmployeeRoleList,
  [EmployeeRole.SCHOOL_MANAGER]: schoolManagerEmployeeRoleList,
  [EmployeeRole.SCHOOL_ADMINISTRATOR]: superAdminEmployeeRoleList,
};

export const employeeRolesList = Object.entries(employeeRoles).map(
  ([key, val]) => ({
    label: val,
    value: parseInt(key),
  })
);
