import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faUserFriends,
  faTable,
  faUser,
  faPlus,
  faCogs,
  faParachuteBox,
  faSchool,
  faArrowsAltH,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation, Link } from "react-router-dom";
import { Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import styled from "styled-components";
import { useAppSelector } from "store";
import { CreditCardFilled } from "@ant-design/icons";
import { EmployeeRole } from "enums";

const { Item: MenuItem, ItemGroup } = Menu;

type DashboardNavigationProps = { transferCount: { childCount: number; staffCount: number } };

const DashboardNavigation = ({ transferCount }: DashboardNavigationProps) => {
  const isNurse = useAppSelector(store => store.user.access.isNurse);
  const location = useLocation();

  const role = useAppSelector(store => store.user.role);

  const isSuperAdmin = role === EmployeeRole.SUPER_ADMIN;

  const isSchoolManagerOrAdministrator =
    !!role && [EmployeeRole.SCHOOL_MANAGER, EmployeeRole.SCHOOL_ADMINISTRATOR].includes(role);

  const defaultLocation = location.pathname.split("/");
  const defaultPath = `${defaultLocation[0]}/${defaultLocation[1]}/${defaultLocation[2]}/${defaultLocation[3]}`;
  const totalTransferCount = transferCount?.childCount + transferCount?.staffCount || 0;
  return (
    <>
      <div
        style={{
          display: "flex",
          backgroundColor: "white",
          height: "100%",
          width: "100%",
          boxShadow: "3px 0px 6px 1px rgba(0,0,0,0.03)",
        }}
      >
        <Menu
          mode="vertical"
          style={{
            width: "100%",
            backgroundColor: "transparent",
            height: "100%",
          }}
          defaultSelectedKeys={["kids"]}
        >
          <StyledItemGroup color="#E50A5C">
            <Menu.Item key="kids" icon={<FontAwesomeIcon icon={faUser} />} style={{ marginTop: "-4px" }}>
              <StyledLink to={`${defaultPath}/kids`}>Kids</StyledLink>
            </Menu.Item>
            <MenuItem key="carers" icon={<FontAwesomeIcon icon={faUserFriends} />}>
              <StyledLink to={`${defaultPath}/carers`}>Carers</StyledLink>
            </MenuItem>
            {!isNurse && (
              <>
                <Menu.Item key="staff" icon={<FontAwesomeIcon icon={faUsers} />}>
                  <StyledLink to={`${defaultPath}/staff`}>Staff</StyledLink>
                </Menu.Item>
                <Menu.Item key="classes" icon={<FontAwesomeIcon icon={faTable} />}>
                  <Link to={`${defaultPath}/classes`}>Classes</Link>
                </Menu.Item>
                {!!isSchoolManagerOrAdministrator && (
                  <StyledTransferMenuItem
                    key="transfers"
                    icon={<FontAwesomeIcon icon={faArrowsAltH} />}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Link
                      to={`${defaultPath}/transfers`}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <span>Transfer</span>{" "}
                      {!!totalTransferCount && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "7px",
                            height: "7px",
                            padding: "4px",
                            backgroundColor: "#E50A5C",
                            color: "white",
                            borderRadius: "50%",
                            fontSize: "14px",
                            fontWeight: "bold",
                            animation: "pendingActionDotblink 1s infinite",
                          }}
                        />
                      )}
                    </Link>
                  </StyledTransferMenuItem>
                )}
                <Menu.Item key="suppliers" icon={<FontAwesomeIcon icon={faParachuteBox} />}>
                  <Link to={`${defaultPath}/suppliers`}>Suppliers</Link>
                </Menu.Item>
                {isSuperAdmin && (
                  <Menu.Item key="singleSchoolFinancials" icon={<CreditCardFilled />}>
                    <Link to={`${defaultPath}/singleSchoolFinancials`}>Financials</Link>
                  </Menu.Item>
                )}
              </>
            )}
            <Menu.Item key="details" icon={<FontAwesomeIcon icon={faSchool} />}>
              <Link to={`${defaultPath}/details`}>School details</Link>
            </Menu.Item>
          </StyledItemGroup>
          {!isNurse && (
            <StyledSubMenu
              key="add"
              icon={<FontAwesomeIcon icon={faPlus} style={{ marginRight: 10 }} />}
              title="Add"
              color="#00A1E1"
            >
              <StyledItemGroup key="add" title="Add" color="#00A1E1">
                <Menu.Item key="addKid">
                  <Link to={`${defaultPath}/addKid`}>Child</Link>
                </Menu.Item>
                <Menu.Item key="addCarer">
                  <Link to={`${defaultPath}/addCarer`}>Carer</Link>
                </Menu.Item>
                <Menu.Item key="addStaff">
                  <Link to={`${defaultPath}/addStaff`}>Staff</Link>
                </Menu.Item>
                <Menu.Item key="addCamp">
                  <Link to={`${defaultPath}/camps/addCamp`}>Camp</Link>
                </Menu.Item>
                <Menu.Item key="addTerm">
                  <Link to={`${defaultPath}/terms/addTerm`}>Term</Link>
                </Menu.Item>
                <Menu.Item key="addClass">
                  <Link to={`${defaultPath}/addClass`}>Class</Link>
                </Menu.Item>
                <Menu.Item key="addSupplier">
                  <Link to={`${defaultPath}/addSupplier`}>Supplier</Link>
                </Menu.Item>
              </StyledItemGroup>
            </StyledSubMenu>
          )}
          {!isNurse && (
            <StyledSubMenu
              key="settings"
              icon={<FontAwesomeIcon icon={faCogs} style={{ marginRight: 10 }} />}
              title="Settings"
              color="#BDD000"
            >
              <StyledItemGroup key="settings" title="Settings" color="#BDD000">
                <Menu.Item key="times">
                  <Link to={`${defaultPath}/times`}>Days & Times</Link>
                </Menu.Item>
                <Menu.Item key="extras">
                  <Link to={`${defaultPath}/extras`}>Extras</Link>
                </Menu.Item>
                <Menu.Item key="shifts">
                  <Link to={`${defaultPath}/shifts`}>Staff Shifts</Link>
                </Menu.Item>
                <Menu.Item key="vacation">
                  <Link to={`${defaultPath}/vacation`}>Holidays</Link>
                </Menu.Item>
                <Menu.Item key="camps">
                  <Link to={`${defaultPath}/camps`}>Camps</Link>
                </Menu.Item>
                <Menu.Item key="termsv2">
                  <Link to={`${defaultPath}/v2/terms`}>Terms</Link>
                </Menu.Item>
                {isSuperAdmin && (
                  <Menu.Item key="financials">
                    <Link to={`${defaultPath}/financials/oneTimeFees`}>Financials</Link>
                  </Menu.Item>
                )}
              </StyledItemGroup>
            </StyledSubMenu>
          )}
        </Menu>
      </div>
    </>
  );
};

export default DashboardNavigation;

const StyledItemGroup = styled(ItemGroup)<{ color: string }>`
  .ant-menu-item {
    border-left: ${({ color }) => `0px solid ${color}`};
    transition: all 0.15s ease-out;
    cursor: pointer;
    padding-left: 16px;
  }

  .ant-menu-item:active,
  .ant-menu-item:hover {
    color: ${({ color }) => `${color}DD`} !important;
    background-color: transparent;
    a {
      color: ${({ color }) => `${color}DD`} !important;
    }
  }
  .ant-menu-item-selected {
    background-color: transparent !important;
    border-left: ${({ color }) => `5px solid ${color}`};
    color: ${({ color }) => `${color}`} !important;

    a {
      color: ${({ color }) => `${color}`} !important;
    }
  }

  .ant-menu-submenu-selected {
    .ant-menu-submenu-selected:active,
    .ant-menu-submenu-selected:hover {
      color: ${({ color }) => `${color}`} !important;
      background-color: transparent;
      a {
        color: ${({ color }) => `${color}`} !important;
      }
    }

    border-left: ${({ color }) => `5px solid ${color}`};
    color: ${({ color }) => `${color}`} !important;
    a {
      color: ${({ color }) => `${color}`} !important;
    }
  }

  .ant-menu-submenu-title {
    background-color: transparent !important;
    color: ${({ color }) => `${color}`} !important;

    a {
      color: ${({ color }) => `${color}`} !important;
    }
  }
`;

const StyledLink = styled(Link)`
  color: inherit;
`;

const StyledSubMenu = styled(SubMenu)<{ color: string }>`
  transition: border-left 0.15s ease-out;
  background-color: transparent !important;
  border-left: ${({ color }) => `0px solid ${color}`};

  a {
    color: ${({ color }) => `${color}`} !important;
  }

  .ant-menu-submenu-title:active,
  .ant-menu-submenu-title:hover {
    color: ${({ color }) => `${color}`} !important;
    a {
      color: ${({ color }) => `${color}`} !important;
    }
  }

  &.ant-menu-submenu-selected {
    border-left: ${({ color }) => `5px solid ${color}`} !important;
    color: ${({ color }) => `${color}`} !important;
  }
`;

const StyledTransferMenuItem = styled(Menu.Item)`
  .ant-menu-title-content {
    width: 100%;
  }
`;
