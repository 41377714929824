import { FC, useState, useCallback } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import useSWR from "swr";
import dayjs from "dayjs";
import { Button, Row, Space, Spin, Table, Typography, message } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Container, Spacer } from "components";
import { axiosAuth } from "helpers";

const { Title } = Typography;
const { Column } = Table;

type RouteParams = {
  id?: string;
};

type Props = RouteComponentProps<RouteParams> & {
  transferCount: {
    childCount: number;
    staffCount: number;
  };
  revalidateTransferCount: () => void;
};
const Transfers: FC<Props> = ({ match, transferCount, revalidateTransferCount }) => {
  const schoolId = match.params.id;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [transferTab, setTransferTab] = useState<"child" | "staff">("child");
  const [transferType, setTransferType] = useState<"out" | "in">("out");
  const {
    data: transfers,
    error,
    revalidate,
  } = useSWR(
    `transfer/list?for=${transferTab}&page=${page}&pageSize=${pageSize}&${
      transferType === "out" ? "fromSchoolId" : "toSchoolId"
    }=${schoolId}`
  );

  const handlePaginationChange = (newPage: number, newPageSize?: number) => {
    setPage(newPage);
    if (newPageSize) setPageSize(newPageSize);
  };

  const handleTransferTypeChange = useCallback((type: "out" | "in") => {
    setTransferType(type);
    setPage(1);
  }, []);

  const handleTransferTab = (type: "child" | "staff") => {
    setTransferTab(type);
    setTransferType("out");
  };

  const handleStatusUpdate = async (id: number, status: "approved" | "rejected") => {
    try {
      const parseData = {
        id,
        status,
        rejectedReason: status === "rejected" ? "Transfer rejected" : "",
        note: status === "approved" ? "Transfer approved" : "",
      };

      await axiosAuth.put(`transfer/update`, {
        ...parseData,
      });

      message.success(`Status updated to ${status}`);
      revalidate();
      revalidateTransferCount()
    } catch (error) {
      message.error("Failed to update status");
    }
  };

  const showPendingAction = (
    transferTab: string,
    type: string,
    transferCount: { childCount: number; staffCount: number }
  ) => {
    if (type === "out") return false;
    if (transferTab === "child" && transferCount?.childCount > 0) return true;
    if (transferTab === "staff" && transferCount?.staffCount > 0) return true;
    return false;
  };

  const normalizedData = transfers?.data?.map((item: any) => {
    const child = item?.child;
    const staff = item?.employee;
    const toSchool = item?.toSchool;
    const fromSchool = item?.fromSchool;
    const name =
      transferTab === "child"
        ? `${child?.firstName} ${child?.lastName}`
        : `${staff?.firstName} ${staff?.lastName}`;

    return {
      ...item,
      name,
      toSchool: toSchool?.name,
      fromSchool: fromSchool?.name,
      transferDate: item?.transferDate ? dayjs.unix(item?.transferDate).format("DD-MM-YYYY") : "",
      status: item?.status?.charAt(0)?.toUpperCase() + item?.status?.slice(1),
      profileLink:
        transferTab === "child"
          ? `/main/schools/${schoolId}/kids/${child?.id}/addKid`
          : `/main/schools/${schoolId}/addStaff/${staff?.id}`,
    };
  });

  return (
    <Spin spinning={!transfers}>
      <Container>
        <Row justify="end">
          <Button
            type={transferTab === "child" ? "primary" : "default"}
            style={{
              marginRight: "20px",
              ...(transferTab === "child" ? styles.activeTab : {}),
            }}
            onClick={() => handleTransferTab("child")}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
              <span>Transfer Kids</span>{" "}
              {transferTab !== "child" && transferCount?.childCount > 0 && <PendingAction />}
            </div>
          </Button>
          <Button
            type={transferTab === "staff" ? "primary" : "default"}
            style={transferTab === "staff" ? styles.activeTab : {}}
            onClick={() => handleTransferTab("staff")}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
              <span> Transfer Staff </span>{" "}
              {transferTab !== "staff" && transferCount?.staffCount > 0 && <PendingAction />}
            </div>
          </Button>
        </Row>
        <Spacer />

        <div style={styles.buttonContainer}>
          {["out", "in"].map(type => (
            <Button
              key={type}
              type="primary"
              style={{
                ...styles.button,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "16px",
                backgroundColor: transferType === type ? "#E50A5C" : "#D0E5F6",
                borderColor: transferType === type ? "#E50A5C" : "#D0E5F6",
                color: transferType === type ? "#FFFFFF" : "#000000",
                boxShadow: "none",
                textShadow: "none",
              }}
              onClick={() => handleTransferTypeChange(type as "out" | "in")}
            >
              {`Transfer ${type.toUpperCase()}`}
              {showPendingAction(transferTab, type, transferCount) && <PendingAction />}
            </Button>
          ))}
        </div>

        <Table
          title={() => (
            <Title level={3} style={{ color: "#E50A5C" }}>
              {`Transfer ${transferType.toUpperCase()}`}
            </Title>
          )}
          dataSource={normalizedData}
          pagination={{
            current: page,
            pageSize,
            total: transfers?.total || 0,
            onChange: handlePaginationChange,
          }}
        >
          <Column title="Name" dataIndex="name" key="name" width="20%" />
          {transferType === "out" && (
            <Column title="New School" dataIndex="toSchool" key="toSchool" width="20%" />
          )}
          {transferType === "in" && (
            <Column title="Former School" dataIndex="fromSchool" key="fromSchool" width="20%" />
          )}
          <Column
            title="Status"
            dataIndex="status"
            key="status"
            width="20%"
            render={status => (
              <span
                style={{
                  color: status === "Approved" ? "#27AE60" : status === "Rejected" ? "#FF0000" : "#000000",
                }}
              >
                {status}
              </span>
            )}
          />
          {transferType === "in" && (
            <>
              <Column title="Transfer Date" dataIndex="transferDate" key="transferDate" width="20%" />
              <Column
                title="Profile"
                key="profileLink"
                width="20%"
                render={row =>
                  row.status === "Rejected" ? (
                    <></>
                  ) : (
                    <Link
                      to={{
                        pathname: row.profileLink,
                        search: "?isPreviewMode=true",
                      }}
                    >
                      View Profile
                    </Link>
                  )
                }
              />
              <Column
                title=""
                key="actions"
                render={row => {
                  return row.status === "Pending" ? (
                    <Space>
                      <CheckOutlined
                        style={{ color: "green", cursor: "pointer", marginRight: "10px" }}
                        onClick={() => handleStatusUpdate(row.id, "approved")}
                      />
                      <CloseOutlined
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => handleStatusUpdate(row.id, "rejected")}
                      />
                    </Space>
                  ) : (
                    <></>
                  );
                }}
              />
            </>
          )}
        </Table>
      </Container>
    </Spin>
  );
};

const PendingAction = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "7px",
        height: "7px",
        padding: "4px",
        backgroundColor: "#E50A5C",
        color: "white",
        borderRadius: "50%",
        fontSize: "14px",
        fontWeight: "bold",
        animation: "pendingActionDotblink 1s infinite",
      }}
    />
  );
};

const styles = {
  buttonContainer: {
    display: "flex",
    backgroundColor: "#D0E5F6",
    padding: "4px",
    borderRadius: "4px",
    width: "max-content",
    marginBottom: "24px",
  },
  button: {
    width: "394px",
  },
  activeTab: {
    backgroundColor: "#BDD000",
    border: "1px solid #BDD000",
    outline: "none",
  },
};

export default Transfers;
