import { Button, Divider, Row, Spin, Table, Typography } from "antd";
import { Dot, Spacer } from "components";
import { axiosAuth } from "helpers";
import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";
import useSWR from "swr";
import { KidDetailsNavigationParams } from "types";

export type SkillsGroupsList = {
  id: number;
  rank: number;
  status: number;
  type: number;
  name: string;
  items: {
    id: number;
    name: string;
    developmentItem: {
      answer: number;
      id: number;
      name: string;
    }[];
  }[];
};

type Res = SkillsGroupsList[];

const { Title, Text } = Typography;
const { Column } = Table;

type AdditionalProps = {
  isPreviewMode?: boolean | undefined;
};
type Props = RouteComponentProps<KidDetailsNavigationParams> & AdditionalProps;

const Skills: FC<Props> = props => {
  const isPreviewMode = !!props?.isPreviewMode;
  const [isSkillUpdatePending, setIsSkillUpdatePending] = useState(false);
  const kidId = props.match.params.kidId;
  const [editedSkills, setEditedSkills] = useState<Record<number, number>>({});
  const [selectedAnswers, setSelectedAnswers] = useState<Record<number, number>>({});
  const { data, error, revalidate } = useSWR<Res>(kidId ? `development-category/config/${kidId}` : null);
  const isPending = !data && !error;

  const answersData = useMemo(() => {
    const aa: Record<number, number> = {};
    data?.forEach(({ items }) => {
      items.forEach(({ developmentItem }) => {
        developmentItem.forEach(({ answer, id }) => {
          aa[id] = answer;
        });
      });
    });
    return aa;
  }, [data]);

  useEffect(() => {
    setSelectedAnswers(answersData);
  }, [answersData]);

  const onSkillPress = useCallback((id, val) => {
    if (!isPreviewMode) {
      setSelectedAnswers(prev => ({ ...prev, [id]: val }));
      setEditedSkills(prev => ({ ...prev, [id]: val }));
    }
  }, []);

  return (
    <Spin spinning={isPending}>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "4px",
          overflow: "hidden",
          boxShadow: "2px 2px 6px 1px rgba(0,0,0,0.02)",
          padding: "24px",
        }}
      >
        {data?.map(({ name, items }) => (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Title level={4} style={{ color: "#E50A5C", margin: "0px" }}>
                {name}
              </Title>
              {!isPreviewMode && (
                <Button
                  loading={isSkillUpdatePending}
                  type="primary"
                  style={{
                    backgroundColor: "#BDD000",
                    borderColor: "#BDD000",
                  }}
                  onClick={async () => {
                    setIsSkillUpdatePending(true);
                    const updatedSkills = Object.entries(editedSkills).map(([key, val]) => ({
                      value: val,
                      id: key,
                    }));
                    await axiosAuth.post(`development-category/config/${kidId}`, updatedSkills);
                    await revalidate();
                    setIsSkillUpdatePending(false);
                  }}
                >
                  Save
                </Button>
              )}
            </div>

            <Spacer />
            {items.map(({ developmentItem, name }) => (
              <table style={{ width: "100%" }}>
                <TableHeaderRow>
                  <th style={{ width: "50%", textAlign: "left" }}>{name}</th>
                  <th style={{ textAlign: "center" }}>Not yet</th>
                  <th style={{ textAlign: "center" }}>Sometimes</th>
                  <th style={{ textAlign: "center" }}>Often</th>
                  <th style={{ textAlign: "center" }}>Always</th>
                </TableHeaderRow>
                {developmentItem.map((devItem, index) => (
                  <TableBodyRow>
                    <td>{devItem.name}</td>
                    {Array.from(new Array(4)).map((_, dotIndex) => (
                      <td>
                        <Dot
                          onClick={() => onSkillPress(devItem.id, (1 + dotIndex) * 10)}
                          isActive={selectedAnswers[devItem.id] === (1 + dotIndex) * 10}
                        />
                      </td>
                    ))}
                  </TableBodyRow>
                ))}
                <Spacer />
              </table>
            ))}
            <Divider style={{ margin: "10px 0px" }} />
          </>
        ))}
      </div>
    </Spin>
  );
};

export default Skills;

const TableHeaderRow = styled.tr`
  background-color: #ededed;

  th {
    font-size: 1.2rem;
    padding: 12px;
  }
`;
const TableBodyRow = styled.tr`
  background-color: white;

  td {
    font-size: 1.2rem;
    padding: 12px;
  }
`;
